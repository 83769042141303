/*
 * File: Router.js
 * Project: autofi-front
 * Author: Simon Gourlet
 * -----
 * Copyright 2022 - Autofi
 */

import { createBrowserRouter } from "react-router-dom";
import Root from "Content/Root/Root";
import ErrorPage from "Content/Error/ErrorPage/ErrorPage";
import { Contact } from "Content/Contact/Contact";
import {CarList } from "Content/Car/CarList/CarList";
import CarService from "Services/Cars/CarService";
import CarElement from "Content/Car/CarElement/CarElement";
import CGV from "Content/CGV/CGV";
import HomeCardHolder from "Content/Home/HomeCardHolder/HomeCardHolder";
import AboutUsHolder from "Content/AboutUs/AboutUsHolder";
import Financement from "Content/Financement/Financement";
import FaqFinancement from "Content/Faq/Faq_financement";
import Login from "Content/Login/Login";
import RouterPathResolver from "Config/Router/RouterPathResolver";
import RGPD from "Content/RGPD/RGPD";
import { InterractiveHeaderProvider } from "./HeaderInteractionProvider";
import CreateAccount from "Content/CreateAccount/CreateAccount";
import ServiceGeneral from "Services/ServiceGeneral";
import Favorite from "Content/Favorite/Favorite";
import { AuthProvider } from "./AuthProvider";
import ProfilePage from "Content/ProfilePage/ProfilePage";
import UserService from "Services/Users/UserService";

export const Router = createBrowserRouter([
  {
    path: `${RouterPathResolver.pathEntryPoint.path}`,
    element: <AuthProvider><InterractiveHeaderProvider><Root children={[]} /></InterractiveHeaderProvider></AuthProvider>,
    // element: <Root children={[]} />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: `${RouterPathResolver.routeContact.path}`,
        element: <Contact Category={null} Energy={null} Brand={null} Model={null} CarId={null} CarTitle={null} Mileage={undefined} />,
      },
      {
        path: `${RouterPathResolver.pathEntryPoint.path}`,
        element: <HomeCardHolder />
      },
      {
        path: `${RouterPathResolver.routeCars.path}`,
        loader:({request})=>{
          let page = 1;
          let filters: string[]= [];
          const orderBy: {name: string, order: string} = {name:"", order:""};
          const url = new URL(request.url);
          const mySearchParams = new URLSearchParams(url.search);
          if (mySearchParams.has("filters"))
            filters = mySearchParams.get('filters')!.split(",");
          if (mySearchParams.has("page"))
            page = parseInt(mySearchParams.get('page')!);
          if (mySearchParams.has('orderedby')) {
            const tmp = mySearchParams.get('orderedby')!.split(",");
            orderBy.name = tmp[0];
            orderBy.order = tmp[1];
          }
          return ServiceGeneral.manageAxiosResponse(() => { return CarService.getCars(page, filters, orderBy)});
        },
        element: <CarList custom={false} />,
        errorElement: <ErrorPage />,
      },
      {
        path: `${RouterPathResolver.routeCarsId.path}`,
        loader: ({params})=> {
          return CarService.getSingleCar(params.id!);
        },
        element: <CarElement />,
        errorElement: <ErrorPage />,
      },
      {
        path: `${RouterPathResolver.routeCgv.path}`,
        element:<CGV />
      },
      {
        path: `${RouterPathResolver.routeRgpd.path}`,
        element:<RGPD />
      },
      {
        path: `${RouterPathResolver.routeSignIn.path}`,
        element: <CreateAccount />,
      },
      {
        path: `${RouterPathResolver.routeLogin.path}`,
        element:<Login />
      },
      {
        path: `${RouterPathResolver.routeProfil.path}`,
        loader: async ({}) => {
          const myProfile = await ServiceGeneral.manageAxiosResponse( () => { return UserService.getMyProfile();});
          return myProfile;
        },
        element: <ProfilePage />,
      },
      {
        path: `${RouterPathResolver.routeFinanceFaq.path}`,
        element:<FaqFinancement />
      },
      {
        path: `${RouterPathResolver.routeFinance.path}`,
        element:<Financement />
      },
      {
        path: `${RouterPathResolver.routeAbout.path}`,
        element:<AboutUsHolder />
      },
      {
        path:`${RouterPathResolver.routeFavorites.path}`,
        loader: () => {
          return ServiceGeneral.manageAxiosResponse(() => { return CarService.getFavoriteCars()});
        },
        element:<Favorite />
      },
      {
        path:`${RouterPathResolver.routeCarsBrand.path}`,
        loader:({params, request})=>{
          let page = 1;
          const orderBy: {name: string, order: string} = {name:"", order:""};
          const mySearchParams = new URL(request.url).searchParams;
          if (mySearchParams.has("page"))
            page = parseInt(mySearchParams.get('page')!);
          if (mySearchParams.has('orderedby')) {
            const tmp = mySearchParams.get('orderedby')!.split(",");
            orderBy.name = tmp[0];
            orderBy.order = tmp[1];
          }
          return CarService.getCarsByBrand(params.brandName!, page, orderBy);
        },
        element: <CarList custom={true} />,
      },
    ]
  },
]);
