/*
 * File: Contact.tsx
 * Project: autofi-front
 * File Created: Thursday, 12th January 2023 03:35:08
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Friday, 24th March 2023 10:07:48
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import { Form } from "react-router-dom";
import { ContactProps } from "Content/Contact/Contact.type";
import { FormEvent, useEffect, useState } from "react";
import translator from "MultiLanguage/TranslationWrapper";
import styles from "Content/Contact/Contact.module.css";
import { AddressContact } from "Content/Contact/AddressContact/AddressContact";
import PersonnalContact from "Content/Contact/PersonnalContact/PersonnalContact";
import VehicleSelectionContact from "Content/Contact/VehicleSelectionContact/VehicleSelectionContact";
import VehicleIntels from "Content/Contact/VehicleSelectionContact/VehicleIntels.type";
import { PersonnalContactType } from "Content/Contact/PersonnalContact/PersonnalContact.type";
import { Address } from "Content/Contact/AddressContact/AddressInterface";
import ContactService from "Services/Contact/ContactService";
import LoadingScreen from "Content/LoadingScreen/LoadingScreen";
import ModalConfirmation from "Content/Modals/ModalConfirmation/ModalConfirmation";

export function Contact(props: ContactProps) {
    const [vehicleIntels, setVehicleIntels] = useState<VehicleIntels>({category: props.Category !== null ? props.Category : undefined,
                                                                        energy: props.Energy !== null ? props.Energy : undefined,
                                                                        brand: props.Brand !== null ? props.Brand : undefined,
                                                                        model: props.Model !== null ? props.Model : undefined,
                                                                        mileage: props.Mileage !== null ? props.Mileage : undefined });
    const [personnalIntels, setPersonnalIntels] = useState<PersonnalContactType>({});
    const [address, setAddress] = useState<Address>({
        houseNumber: "",
        country: "",
        street: "",
        postalCode: "",
        city: "",
        score: 0
    });
    const [spinner, setSpinner] = useState(false);
    const [curStep, setCurStep] = useState<number>( props.Category != null && props.Category != undefined ? 1 : 0);
    const [comments, setComments] = useState<string>("");
    const [buttonEnable, setButtonEnable] = useState<boolean>(true);
    const [sendEnable, setSendEnable] = useState<boolean>(false);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [isOkay, setIsOkay] = useState<boolean>(false);

    const enableNext = (val?: boolean) => {
        if (val !== undefined) {
            setButtonEnable(!val);
            setButtonEnable(val);
        } else {
            setButtonEnable(false);
            setButtonEnable(true);
        }
    };

    const checkSendEnable = () => {
        return personnalIntels.email !== "" && personnalIntels.phone !== "" && personnalIntels.firstName !== "" && personnalIntels.lastName !== "" && address.postalCode !== "";
    };

    useEffect(() => {
        if (buttonEnable && checkSendEnable())
            setSendEnable(true);
        else
            setSendEnable(false);
    }, [buttonEnable]);

    const fieldsAreFilled = (e: FormEvent<HTMLFormElement>) => {
        let ret = true;
        if (personnalIntels.firstName === undefined || personnalIntels.firstName === "") {
            e.preventDefault();
            ret = false;
        }
        else if (personnalIntels.lastName === undefined || personnalIntels.lastName === "") {
            e.preventDefault();
            ret = false;
        }
        else if (personnalIntels.email === undefined || personnalIntels.email === "") {
            e.preventDefault();
            ret = false;
        }
        else if (personnalIntels.phone === undefined || personnalIntels.phone === "") {
            e.preventDefault();
            ret = false;
        }
        else if (address.postalCode === "") {
            e.preventDefault();
            ret = false;
        }
        return ret;
    };

    const verifyFields = () => {
        let ret = true;
        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(personnalIntels.email!)) {
            ret = false;
        }
        else if (!/^[\+]?[(]?[0-9]{2,3}[)]?([-\s\. ]?[0-9]{2,3}){4,6}$/.test(personnalIntels.phone!)) {
            ret = false;
        }
        else if (!/^\d{5,6}$/.test(address.postalCode!)) {
            ret = false;
        }
        return ret;
    };

    const handleNewContact = async (e: FormEvent<HTMLFormElement>) => {
        setSpinner(true);
        if (fieldsAreFilled(e) && verifyFields()) {
            personnalIntels.address = address;
            await ContactService.addNewContactRequest({carIntels: vehicleIntels, personnalIntel: personnalIntels, comments: {text: comments}})
            .then(res => {
                if (res != null && res.message == "Request succes") {
                    window.scroll(0,0);
                    setIsOkay(true);
                }
                setOpenModal(true);
            });
        }
        setSpinner(false);
        return true;
    };
    const handleToggleModal = (event: React.MouseEvent<HTMLElement>) => {
        setOpenModal(false);
    };

    const handleBackButton = (e: any) => {
        e.preventDefault();
        setCurStep(curStep - 1);
        if (curStep == 1)
            setButtonEnable(true);
    };
    useEffect(()=>{
        enableNext(verifyFields());
    }, [address, personnalIntels]);
    return (
        <div className={`${styles.contactHolder}`}>
            <div className={`${styles.imagePlaceholder}`}>
                <img src="/contactLandScape.png" alt="Car stopped on beach" />
            </div>
            <div className={`${styles.contactForm}`}>
                <Form onSubmit={handleNewContact} className={`container ${styles.formStyle}`}>
                    {
                    curStep == 0 ?
                        <>
                            <VehicleSelectionContact vehicleIntels={vehicleIntels} setVehicleIntel={setVehicleIntels} />
                            <div className={`${styles.buttonPos}`}>
                                <button className={`${styles.buttonStep}`} onClick={(e: any) => {e.preventDefault(); setCurStep(curStep + 1);}}>{translator.decode("dynamic.next")}</button>
                            </div>
                        </>
                        : null
                    }
                    {
                    curStep == 1 ?
                        <>
                            <PersonnalContact enableNext={enableNext} personnalIntels={personnalIntels} setPersonnalIntels={setPersonnalIntels} />
                            <AddressContact enableNext={enableNext} address={address} setAddress={setAddress} />
                            <div className={`${styles.inputHolder}`}>
                                {/* <label htmlFor="commentary" className={styles.input}> {`${translator.decode("contact.comments")}`} </label> */}
                                <textarea id="commentary" className={`${styles.commentaryField}`} value={comments} maxLength={1024} onChange={(e: any) => {setComments(e.target.value)}} name="comments" placeholder={`${translator.decode("contact.addComments")}`} rows={5} cols={80} />
                            </div>
                            <p className={`${styles.mandatory} `}>{translator.decode(`contact.mandatory`)}</p>
                            <div className={`${styles.buttonPos} ${styles.buttonPosSplit}`}>
                                {
                                    curStep > 0 && props.CarId == undefined ? <button className={`${styles.buttonStep}`} onClick={handleBackButton}>{translator.decode("dynamic.previous")}</button> : null
                                }
                                <button className={`${(sendEnable && !spinner) ? styles.buttonNext : styles.buttonStepDisabled}`} disabled={!sendEnable || spinner}>{translator.decode("dynamic.send")}</button>
                            </div>
                            {
                                spinner ? <LoadingScreen /> : null
                            }
                        </>
                        : null
                    }
                </Form>
            </div>
            <ModalConfirmation show={openModal} onClose={handleToggleModal} closeModal={handleToggleModal} themeColor={""} isOkay={isOkay}/>
        </div>
    );
}
